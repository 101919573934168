import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const InputRange = ({ name , value, units = '', step = 1, min = 0, max= 100, startLabel = null, endLabel = null, onChange = () => {} }) => {
    const [intermediateValue, setIntermediateValue] = useState(value);

    const handleChange = (e) => {
        onChange(name, e.target.value);
    };

    const handleInputChange = (e) => {
        setIntermediateValue(e.target.value);
    };

    useEffect(() => {
        setIntermediateValue(value);
    }, [value]);


    return (
        <div className="input-range__container min-w-3xl	">
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={intermediateValue}
                onChange={handleInputChange}
                onMouseUp={handleChange}
                className={`input-range w-full`}
                style={{
                    '--value': intermediateValue,
                    '--min': min,
                    '--max': max,
                }}
            />
            { intermediateValue &&
                <div
                    className="input-range__label"
                    style={{
                        left: `calc(${(intermediateValue - min) / (max - min) * 100}% - 15px)`,
                    }}
                >
                    {intermediateValue + " " +units}
                </div>
            }
            <div className={"flex justify-between text-xs"}>
                <span className={"color-gray-dark-pa"}>{min}</span>
                <span className={"color-gray-dark-pa"}>{max}</span>
            </div>
            {  startLabel &&
                endLabel &&
                <div className={"flex justify-between text-xs mt-3"}>
                    <span className={"max-w-44"}>{startLabel}</span>
                    <span className={"max-w-44 text-right"}>{endLabel}</span>
                </div>

            }
        </div>
    );
};

InputRange.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default InputRange;