import { useState } from 'react';

const Tooltip = ({ text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div className="relative inline-block ml-2">
            <button
                data-ripple-light="true"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="select-none bg-lite-pa text-black border-gray-pa border transition w-5 h-5 rounded-full text-center align-middle font-sans text-xs font-bold uppercase text-black shadow-md transition-all focus:outline-none"
            >
                ?
            </button>
            {showTooltip && (
                <div
                    className="absolute bottom-full mb-2 z-50 rounded-lg bg-black py-1.5 px-3  transition-all duration-200 origin-bottom opacity-100 scale-100 pointer-events-none"
                    style={{ transform: 'translateX(-20px)', left: '10px'  }}
                >
                    <div className="tooltip-arrow" />
                    <div className={"min-w-72 font-sans text-sm font-normal text-white"}>{text}</div>
                </div>
            )}
        </div>
    );
};

export default Tooltip;