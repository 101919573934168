
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import dateProvider from "../../../provider/DateProvider";
import Patient from "../../../models/Patient";
import FieldProfile from "./Profile/FieldProfile";
import FieldSetVisitForm from "./Visit/Part/FieldSetVisitForm";
import EditFieldVisitForm from "./Visit/Part/EditFieldVisitForm";
import DocFields from "./Profile/DocFields";
import apiService from "../../../provider/ApiService";

const FieldSetProfileForm = ({title, id, children }) => {
    return <div className={"field-set-visit mt-5"} id={id}>
        {(title &&
            <div className={"field-set-visit__title relative"}>
                <span className={"subtitle bg-gray-pa text-xs color-gray-dark-pa uppercase z-10 inline-block relative pr-3"}>{title}</span>
            </div>
        )}
        <div className={"flex flex-col gap-5 my-5"}>
            {children}
        </div>

    </div>
}

const PatientProfileContent = () => {

    const [diagnosis, setDiagnosis] = useState('');
    const [patient, setPatient] = useState(null);

    const { id } = useParams();


    const fetchDiagnostic = async () => {
        const data = await apiService.post('/iin_read', { iin: id });

        if (data.ok && data.iins ) {
            localStorage.setItem("patient", JSON.stringify(data.iins));
            const patientData = new Patient(data.iins);
            setPatient(patientData);
            setDiagnosis(await patientData.getDiagnosis());
        }
    }

    useEffect(() => {
        fetchDiagnostic();
    }, []);


    const groupOptions = [{name: "1", value: "1"}, {name: "2", value: "2"}, {name: "3", value: "3"}];

    return (patient && <div className={" flex flex-col px-8 py-5 gap-4"}>
        <p  className={"text-2xl flex items-center   uppercase font-medium "}>
            {patient.getValue("fio")}
        </p>
        <div className={"flex gap-5"}>
            <label className={"w-60 text-sm"}>ИИН пациента</label>
            <div className={"text-base"}>{patient.getValue("iin")}</div>
        </div>
        <div className={"flex gap-5"}>
            <label className={"w-60 text-sm"}>Дата рождения</label>
            <div className={"text-base"}>{patient.getBirthday()}</div>
        </div>
        <DocFields iin={patient}/>
        <FieldProfile placeholder={""} typeFieldForm={"checkbox"} fieldObject={"diagnosis"} iin={patient} label={"Диагноз"}/>
        <FieldProfile placeholder={""} typeFieldForm={"radio"} fieldObject={"gender"} iin={patient} label={"Пол"}/>
        <FieldProfile placeholder={""} typeFieldForm={"radio"} fieldObject={"race"} iin={patient} label={"Раса"}/>
        <FieldProfile placeholder={""} typeFieldForm={"range"} fieldObject={"height"} iin={patient} label={"Рост"} min={"100"} max={"230"} units={"см."} />
        <FieldProfile placeholder={""} typeFieldForm={"range"} fieldObject={"weight"} iin={patient} label={"Вес"} min={"30"} max={"250"} units={"кг."} step={"0.1"} />
        <FieldProfile placeholder={""} typeFieldForm={"textarea"} fieldObject={"home_address"} iin={patient} label={"Домашний адрес"}/>
        <FieldProfile placeholder={""} typeFieldForm={"text"} fieldObject={"telephone"} iin={patient} label={"Контактный телефон"}/>
        <FieldProfile placeholder={""} typeFieldForm={"text"} fieldObject={"medical_organization_of_attachment"} iin={patient} label={"Медицинская организация прикрепления"}/>
        <FieldProfile placeholder={""} typeFieldForm={"text"} fieldObject={"doctor_fio"} iin={patient} label={"Лечащий (направляющий) врач"}/>

        <FieldSetProfileForm title={"Социально - экономический статус"} className={"mt-10"}>
            <FieldProfile placeholder={""} typeFieldForm={"radio"} fieldObject={"education"} iin={patient} label={"Образование"}/>
            <FieldProfile placeholder={""} typeFieldForm={"radio"} fieldObject={"employment"} iin={patient} label={"Трудоустройстов"}/>
        </FieldSetProfileForm>
        <FieldSetProfileForm title={"Инвалидность"}>
            <FieldProfile placeholder={""} typeFieldForm={"radio"} fieldObject={"disability_group"} iin={patient} label={"Группа инвалидности"}  options={groupOptions} />
            <FieldProfile
                placeholder={""}
                typeFieldForm={"period"}
                iin={patient}
                label={"Год установления инвалидности"}
                fieldNameStart={"disability_year_start"}
                fieldObject="disability_year"
                fieldNameEnd={"disability_year_end"}/>

        </FieldSetProfileForm>
    </div>);
}

export default PatientProfileContent;