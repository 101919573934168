import Button from "../../../../../Part/Form/Button";
import {useEffect, useState} from "react";
import EditFieldVisitForm from "../../Part/EditFieldVisitForm";
import fracturesProvider from "../../../../../../provider/FracturesProvider";
import fieldChangeProvider from "../../../../../../provider/FieldChangeProvider";


const FieldSetFracturesForm = ({title, children}) => {
    return <div className={""}>
        <div className={"text-base"}>{title}</div>
        {children}
    </div>;
}

const DeleteFracturesForm = () => {

    return <div className={"cursor-pointer"}  >
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.92472 6.08217L11.6122 0.494671C11.6908 0.401814 11.6247 0.260742 11.5033 0.260742H10.0783C9.99437 0.260742 9.91401 0.298242 9.85865 0.362528L5.99258 4.97146L2.12651 0.362528C2.07294 0.298242 1.99258 0.260742 1.90687 0.260742H0.481867C0.360439 0.260742 0.294367 0.401814 0.372939 0.494671L5.06044 6.08217L0.372939 11.6697C0.355338 11.6904 0.344047 11.7157 0.340404 11.7426C0.336762 11.7695 0.340922 11.7969 0.352391 11.8216C0.36386 11.8462 0.382156 11.867 0.405107 11.8816C0.428057 11.8961 0.454698 11.9037 0.481867 11.9036H1.90687C1.9908 11.9036 2.07115 11.8661 2.12651 11.8018L5.99258 7.19289L9.85865 11.8018C9.91222 11.8661 9.99258 11.9036 10.0783 11.9036H11.5033C11.6247 11.9036 11.6908 11.7625 11.6122 11.6697L6.92472 6.08217Z" fill="black" fill-opacity="0.45"/>
        </svg>
    </div>

}

const FracturesVisitForm = ({visit, fracturesKey, deleteFractures}) => {

    const [fractures, setFractures] = useState(null);

    useEffect( () => {

        const fetchFractures = async (fracturesKey) => {
            const fracturesData = await fracturesProvider.getFractures(fracturesKey);
            setFractures(fracturesData);
        }

        fetchFractures(fracturesKey) ;

    }, [fracturesKey]);


    const yearOptions = [];
    for(let i = 1900; i < 2100; i++) {
        yearOptions.push({name: i, value: i});
    }

    const booleanOptions = [{name: "Нет", value: "0"}, {name: "Да", value: "1"}];

    if (fractures) {
        return  <div className={"relative border bg-white p-5 flex flex-col  gap-5 "}>
            <div className={"absolute right-5 top-5"} onClick={(e) => {deleteFractures(fractures.getId())}}><DeleteFracturesForm/></div>
            <FieldSetFracturesForm title={"Название"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"text"} objectType={"fractures"} fieldObject={"fractures_text"} fractures={fractures} placeholder={"Напишите название"}/>
            </FieldSetFracturesForm>
            <div className={"flex gap-2 items-center"}>
                <span className={"text-base"}>Год установления</span>
                <EditFieldVisitForm visit={visit} typeFieldForm={"select"} objectType={"fractures"} fieldObject={"fractures_year"} fractures={fractures} size={"small"} options={yearOptions} />
            </div>
            <div className={"flex gap-2 items-center flex-wrap"}>
                <span className={"text-base"}>Патологический перелом</span>
                <EditFieldVisitForm visit={visit} typeFieldForm={"boolean"} objectType={"fractures"} fieldObject={"is_fractures"} fractures={fractures} size={"small"} options={booleanOptions} width={"w-20"}/>
            </div>
        </div>
    }

    return  <div></div>;
}

const FracturesTreatmentVisitForm = ({visit}) => {

    const [listFractures, setListFractures ] = useState(visit.getPatient().getValue("fractures"));

    useEffect(() => {
        setListFractures(visit.getPatient().getValue("fractures"));
    },[visit]);

    const handleAddFractures = async () => {
        const data = await fracturesProvider.createFractures(visit.getPatient().getIIN(), visit.getId());
        if (data) {
            setListFractures([...listFractures, data.fractures_id]);
        }
    }

    const handleDeleteFractures = async (fracturesKey) => {
        await fieldChangeProvider.setFieldIIN(visit.getPatient().getIIN(), "fractures", fracturesKey , 0);
        setListFractures((prevListFractures) => {
            return prevListFractures.filter(item => item !== fracturesKey);
        });
    }

    return <div className={"flex gap-5 items-start"}>
            <div className={"w-60 text-sm"}>Переломы</div>
            <div className={"radiography-list flex flex-col grow max-w-xl gap-3"}>
                {
                    listFractures.map((fracturesItemKey, index) => {
                        return <FracturesVisitForm fracturesKey={fracturesItemKey} visit={visit} deleteFractures={handleDeleteFractures}/>
                    })
                }
                <div className={"mt-2"}>
                    <Button color={"lite"} size={"small"} label={"Добавить"} onClick={handleAddFractures}/>
                </div>
            </div>
        </div>;

}

export default FracturesTreatmentVisitForm;