
import React, {useEffect, useState} from 'react';
import ItemSidebarMenu from "./Menu/ItemSidebarMenu";
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import NewVisitButton from "./Menu/NewVisitButton";
import ListVisits from "./Menu/ListVisits";
import {useListVisitsData} from "../../../contexts/ListVisitsContext";
import patient from "../../../models/Patient";
import DropDownMenu from "./Menu/DropDownMenu";
import SidebarSubMenuItem from "./Menu/DropDownSubMenuItem";
import ButtonPrintMenu from "./Menu/ButtonPrintMenu";
import Patient from "../../../models/Patient";
import patientVisitProvider from "../../../provider/PatientVisitProvider";
import PatientVisit from "../../../models/PatientVisit";

const ItemVisitContainerMenu = ({children}) => {
    return<div className={"py-3 px-5 border-b bg-white "}>
        {children}
    </div>;
}

const LinkBigItemVisitMenu = ({href, name}) => {

    const handleClick = (event) => {
        event.preventDefault();

        const linkText = event.target.textContent.trim();

        // Найти все заголовки h2
        const headers = document.querySelectorAll('h2');

        // Пройтись по заголовкам и найти соответствующий текст
        headers.forEach((header) => {
            if (header.textContent.trim() === linkText) {
                header.scrollIntoView({ behavior: 'smooth' });
            }
        });
    };

    return <Link to={href} className={"font-black text-bold text-sm uppercase"} onClick={handleClick}>{name}</Link>
}


const PatientVisitSidebar = () => {
    const { listVisit, setListVisit } = useListVisitsData();
    const { id } = useParams();

    let visit = null;

    if (listVisit) {
        visit = listVisit.find(visitItem => visitItem.getId() === id);
    }

    useEffect(  () => {
        const fetchVisit = async () => {

            const patientJson = localStorage.getItem("patient") ? JSON.parse(localStorage.getItem("patient")) : {};

            const visitsArray = [];
            if (patientJson) {
                const patient = new Patient(patientJson);
                await patient.updateStorage();
                const data = await patientVisitProvider.getVisitJson(id);

                if (data) {
                    visit = new PatientVisit(data);
                    visit.setPatient(patient);
                }

                let index = 0;
                const count = patientJson.visits.length;

                for (const visitKey of [...patientJson.visits].reverse()) {
                    const data = await patientVisitProvider.getVisitJson(visitKey);
                    if (data) {
                        const patientVisit = new PatientVisit(data);
                        await patientVisit.setPatient(patient);
                        const number = count - index ;

                        await patientVisit.setNumber(number);
                        visitsArray.push(patientVisit);
                        index++;
                    }
                }
                setListVisit(visitsArray);


            }
        }

        if (!visit && id) {
            fetchVisit();
        }
    }, [visit, id]);
    //Если страница обновлена, то нужно считать заново данные по визиту


    if (!visit) {
        return <div></div>
    }

    return (
        <div className={'border-r   w-60 min-h-screen'}>
            <ItemVisitContainerMenu>
                <div className={"text-xs color-gray-dark-pa"}>
                    {visit.getAppearTime()}
                </div>
                <div className={"uppercase text-2xl color-green-pa font-medium pt-3"}>
                    Приём №{visit.getNumber()}
                </div>
            </ItemVisitContainerMenu>
            <ItemVisitContainerMenu>
                <a href={"/patient/profile/" + visit.getPatient().getIIN() } className={"flex gap-4 py-2.5 px-1"}>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 13L1 7L7 1" stroke="#CCCDD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span className={"color-gray-dark-pa text-sm font-bold uppercase"}>Назад</span>
                </a>
            </ItemVisitContainerMenu>
            <div className={"list-sidebar-zebra"}>
                <ItemVisitContainerMenu>
                    <DropDownMenu title={"Идентификация"}>
                        <SidebarSubMenuItem name={"Основное"} link={"#base"}/>
                        <SidebarSubMenuItem name={"Социально-экономический"} link={"#soc-status"}/>
                        <SidebarSubMenuItem name={"Поражение суставов"} link={"#sustavs"}/>
                        <SidebarSubMenuItem name={"Инвалидоность"} link={"#invalidnost"}/>
                        <SidebarSubMenuItem name={"Вредные привычки"} link={"#privichki"}/>
                    </DropDownMenu>
                </ItemVisitContainerMenu>
                <ItemVisitContainerMenu>
                    <LinkBigItemVisitMenu href={"#svedeniy"} name={"Сведенья о заболевании НА МОМЕНТ РЕГИСТРАЦИИ"}/>
                </ItemVisitContainerMenu>
                <ItemVisitContainerMenu>
                    <LinkBigItemVisitMenu href={"#analisis"} name={"Анализы"}/>
                </ItemVisitContainerMenu>
                <ItemVisitContainerMenu>
                    <LinkBigItemVisitMenu href={"#rengen"} name={"Рентгенография"}/>
                </ItemVisitContainerMenu>
                <ItemVisitContainerMenu>
                    <DropDownMenu title={"Осмотр пациента"}>
                        <SidebarSubMenuItem name={"ОЦЕНКА СИМПТОМОВ"} link={"#symptoms"}/>
                        <SidebarSubMenuItem name={"Физикальный осмотр пациента"} link={"#fisical"}/>
                        <SidebarSubMenuItem name={"Поражение суставов"} link={"#sustav"}/>
                        <SidebarSubMenuItem name={"Внесуставные проявления"} link={"#vnesustav"}/>
                        <SidebarSubMenuItem name={"Сопутствующее"} link={"#soputstvuyshie"}/>
                    </DropDownMenu>
                </ItemVisitContainerMenu>
                <ItemVisitContainerMenu>
                    <LinkBigItemVisitMenu href={"#svedenie"} name={"СВЕДЕНИЯ О ЛЕЧЕНИИ  ЗАБОЛЕВАНИЯ"}/>
                </ItemVisitContainerMenu>
                <ItemVisitContainerMenu>
                    <DropDownMenu title={"ОПРОСНИК HAQ-DI"}>
                        <SidebarSubMenuItem name={"Одевание и уход за собой"} link={"#odevanie"}/>
                        <SidebarSubMenuItem name={"Вставание"} link={"#vstavanie"}/>
                        <SidebarSubMenuItem name={"Прием пищи"} link={"#priempichi"}/>
                        <SidebarSubMenuItem name={"Ходьба"} link={"#hodba"}/>
                        <SidebarSubMenuItem name={"Вспомогательные средства"} link={"#vspomogatsredstva"}/>
                        <SidebarSubMenuItem name={"Помощь"} link={"#pomoch"}/>
                        <SidebarSubMenuItem name={"Личная гигиена"} link={"#gigiena"}/>
                        <SidebarSubMenuItem name={"Доставание предметов"} link={"#dostavaniepredmetov"}/>
                        <SidebarSubMenuItem name={"Хватательные движения"} link={"#hvatatelnie"}/>
                        <SidebarSubMenuItem name={"Другие действия"} link={"#drugiedeistviya"}/>
                        <SidebarSubMenuItem name={"Вспомогательные средства"} link={"#vspomogatsredstva-2"}/>
                        <SidebarSubMenuItem name={"Помощь"} link={"#pomoch-2"}/>
                    </DropDownMenu>
                </ItemVisitContainerMenu>
            </div>
            <ButtonPrintMenu visit={visit}/>
        </div>
    );
}

export default PatientVisitSidebar;