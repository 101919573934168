import React from "react";
import {Link} from "react-router-dom";

const SidebarSubMenuItem = ({name, link}) => {

    const handleClick = (event) => {
        event.preventDefault();

        const linkText = event.target.textContent.trim();

        // Найти все заголовки h2
        const headers = document.querySelectorAll('.subtitle');

        // Пройтись по заголовкам и найти соответствующий текст
        headers.forEach((header) => {
            if (header.textContent.trim() === linkText) {
                header.scrollIntoView({ behavior: 'smooth' });
            }
        });
    };

    return <Link to={link} className="color-gray-dark-pa text-xs uppercase" onClick={handleClick}>{name}</Link>;
}

export default SidebarSubMenuItem;
