import FieldSetVisitForm from "../Part/FieldSetVisitForm";
import EditFieldVisitForm from "../Part/EditFieldVisitForm";
import Button from "../../../../Part/Form/Button";
import {useEffect, useState} from "react";
import medicineProvider from "../../../../../provider/MedicineProvider";
import fracturesProvider from "../../../../../provider/FracturesProvider";
import directoryProvider from "../../../../../provider/DirectoryProvider";
import Select from "../../../../Part/Form/Select";
import fieldChangeProvider from "../../../../../provider/FieldChangeProvider";
import TakeMedicine from "../../../../../models/TakeMedicine";
import DeleteButton from "../../../../Part/Form/DeleteButton";

const FieldSetMedicinesForm = ({title, children}) => {
    return <div className={"flex gap-4 items-center"}>
        <div className={"text-base"}>{title}</div>
        {children}
    </div>;
}

const DeleteMedicinesForm = () => {

    return <div className={"cursor-pointer"}  >
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.92472 6.08217L11.6122 0.494671C11.6908 0.401814 11.6247 0.260742 11.5033 0.260742H10.0783C9.99437 0.260742 9.91401 0.298242 9.85865 0.362528L5.99258 4.97146L2.12651 0.362528C2.07294 0.298242 1.99258 0.260742 1.90687 0.260742H0.481867C0.360439 0.260742 0.294367 0.401814 0.372939 0.494671L5.06044 6.08217L0.372939 11.6697C0.355338 11.6904 0.344047 11.7157 0.340404 11.7426C0.336762 11.7695 0.340922 11.7969 0.352391 11.8216C0.36386 11.8462 0.382156 11.867 0.405107 11.8816C0.428057 11.8961 0.454698 11.9037 0.481867 11.9036H1.90687C1.9908 11.9036 2.07115 11.8661 2.12651 11.8018L5.99258 7.19289L9.85865 11.8018C9.91222 11.8661 9.99258 11.9036 10.0783 11.9036H11.5033C11.6247 11.9036 11.6908 11.7625 11.6122 11.6697L6.92472 6.08217Z" fill="black" fill-opacity="0.45"/>
        </svg>
    </div>

}
const MedicinesVisitForm = ({visit,  medicineType, takeMedicine, listMedicines, deleteTakeMedicines}) => {

    const [title, setTitle] = useState(null);
    const [isUsed, setIsUsed] = useState(true);
    const [medicines, setMedicines] = useState(null);

    const handleUpdateTitle = async () => {

        if (medicines && medicines.name) {
            const short = await takeMedicine.getShortTitle();
            console.log(short);
            setTitle(medicines.name+"/" + short);
        }
    }

    useEffect( () => {
        const fetchMedicines = async () => {
            const medicines = listMedicines.filter(item => item.value === takeMedicine.getValue("medicines")).shift();
            if (medicines) {
                setMedicines(medicines);
                await handleUpdateTitle();
            }
            setIsUsed(takeMedicine.getValue("is_used"));
        }

        fetchMedicines() ;

    }, [listMedicines, visit]);

    const handleIsOpen = (value) => {
        setIsUsed(value === "1");
    }

    const booleanOptions = [{name: "Да", value: "1"}, {name: "Нет", value: "0"}];

    if (takeMedicine) {
        return  <div className={"relative border bg-white p-5 flex flex-col  gap-5 "}>
            <div className={"uppercase border-b pb-2"}>{title}</div>
            <div className={"absolute right-5 top-5"} >
                <DeleteButton onDelete={deleteTakeMedicines} itemId={takeMedicine.getId()}/>
            </div>
            <FieldSetMedicinesForm title={"Дозировка"}>
                <div className={"flex gap-2"}>
                    <EditFieldVisitForm size={"small"} visit={visit} typeFieldForm={"text"} objectType={"takeMedicine"} fieldObject={"dosage_text"} takeMedicine={takeMedicine} placeholder={""} width={"w-20"} onChange={handleUpdateTitle}/>
                    <EditFieldVisitForm size={"small"} visit={visit} typeFieldForm={"select"} objectType={"takeMedicine"} fieldObject={"measurement"} takeMedicine={takeMedicine} onChange={handleUpdateTitle}  />
                </div>
            </FieldSetMedicinesForm>
            <FieldSetMedicinesForm title={"Способ введения"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"select"} objectType={"takeMedicine"} fieldObject={"ways_of_use"} takeMedicine={takeMedicine} size={"small"} onChange={handleUpdateTitle} />
            </FieldSetMedicinesForm>
            <FieldSetMedicinesForm title={"Кратность приёма"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"text"} objectType={"takeMedicine"} fieldObject={"frequency_of_reception"} takeMedicine={takeMedicine} size={"small"} onChange={handleUpdateTitle} />
            </FieldSetMedicinesForm>

            <FieldSetMedicinesForm title={"Принимается"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"boolean"} objectType={"takeMedicine"} fieldObject={"is_used"} takeMedicine={takeMedicine} size={"small"} options={booleanOptions} width={"w-20"} onChange={handleIsOpen}/>
            </FieldSetMedicinesForm>
            <FieldSetMedicinesForm title={"Начало приема"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"date"} objectType={"takeMedicine"} fieldObject={"use_start_time"} takeMedicine={takeMedicine} size={"small"} width={"w-20"} />
            </FieldSetMedicinesForm>
            { !isUsed &&
                <div className={"flex flex-col  gap-5"}>
                    <FieldSetMedicinesForm title={"Конец приёма"}>
                        <EditFieldVisitForm visit={visit} typeFieldForm={"date"} objectType={"takeMedicine"} fieldObject={"use_end_time"} takeMedicine={takeMedicine} size={"small"}   width={"w-20"}/>
                    </FieldSetMedicinesForm>
                    <FieldSetMedicinesForm title={"Причина отмены препарата"}>
                        <EditFieldVisitForm visit={visit} typeFieldForm={"select"} objectType={"takeMedicine"} fieldObject={"reasons_for_drug_withdrawal"} takeMedicine={takeMedicine} size={"small"}  />
                    </FieldSetMedicinesForm>
                    <FieldSetMedicinesForm title={"Какое явление?"}>
                        <EditFieldVisitForm visit={visit} typeFieldForm={"text"} objectType={"takeMedicine"} fieldObject={"what_phenomenon"} takeMedicine={takeMedicine} size={"small"} width={"w-96"} placeholder={"Напишите комментарий"}/>
                    </FieldSetMedicinesForm>
                </div>
            }
            <FieldSetMedicinesForm title={"Комментарий"}>
                <EditFieldVisitForm visit={visit} typeFieldForm={"text"} objectType={"takeMedicine"} fieldObject={"comment"} takeMedicine={takeMedicine} size={"small"} width={"w-96"} placeholder={"Напишите комментарий"}/>
            </FieldSetMedicinesForm>
        </div>
    }

    return  <div></div>;
}

const AddForm = ({listMedicines, medicineType, visit, addListTakeMedicines}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [medicinesValue, setMedicinesValue] = useState('');

    const listMedicinesOptions = listMedicines.map(item => {return {"name": item.name, "value": item.value}});
    listMedicinesOptions.unshift({"name": "Выберите значение", "value": ""});

    const handleOpenAddForm = () => {
        setIsOpen(true);
    }

    const handleSetTypeForm = (name, value) => {
        setMedicinesValue(value);
    }

    const handleAddMedicines = async () => {
        const data = await medicineProvider.createTakeMedicine(visit.getPatient().getIIN(), visit.getId(), medicineType);
        if (data) {
            const setMedicine = await fieldChangeProvider.setFieldTakeMedicine(data.takemedicine_id,"medicines", medicinesValue);
            addListTakeMedicines(data.takemedicine_id);
        }
        setIsOpen(false);
    }

    if (isOpen) {
        return <div className={"mt-2 flex gap-2"}>
            <Select name={"medicines_type"} options={listMedicinesOptions} value={""} width={"w-96"} onChange={handleSetTypeForm}/>
            <Button color={"lite"} size={"small"} label={"Добавить"} onClick={handleAddMedicines}/>
        </div>;
    } else {
        return <div className={"mt-2"}>
            <Button color={"lite"} size={"small"} label={"Добавить"} onClick={handleOpenAddForm}/>
        </div>;
    }
}

const MedicinesListForm = ({title, medicineType, visit, listMedicines}) => {
    const [listTakeMedicines, setListTakeMedicines] = useState([]);

    useEffect(() => {
        const fetchTakeMedicine = async () => {
            const takeMedicines = await medicineProvider.getTakeMedicineByType(visit.getPatient().getIIN(), medicineType );
            const listTakeMedicinesData = [];
            if (takeMedicines && takeMedicines.length > 0) {
                for (const takeMedicineData of takeMedicines) {
                    const takeMedicine = new TakeMedicine(takeMedicineData);
                    listTakeMedicinesData.push(takeMedicine);
                }
            }
            setListTakeMedicines(listTakeMedicinesData);
        }

        fetchTakeMedicine();
    },[visit]);

    const handleAddMedicines = async (takemedicineId) => {
        const takeMedicines = await medicineProvider.getTakeMedicine(visit.getPatient().getIIN(), takemedicineId );
        const takeMedicine = new TakeMedicine(takeMedicines);
        setListTakeMedicines([...listTakeMedicines, takeMedicine]);
    }

    const handleDeleteTakeMedicines = async (takeMedicineId) => {
        await fieldChangeProvider.setFieldTakeMedicine(takeMedicineId, "is_del", true);
        const newListMedicines = listTakeMedicines.filter(item => item.getId() !== takeMedicineId);
        setListTakeMedicines(newListMedicines);
    }


    return <div className={"flex gap-5 items-start"}>
        <div className={"w-60 text-sm"}>{title}</div>
        <div className={"flex flex-col grow max-w-xl gap-3"}>
            {
                listTakeMedicines.map((takeMedicine, index) => {
                    //const title = listMedicines.read
                    return <MedicinesVisitForm  medicineType={medicineType} takeMedicine={takeMedicine} visit={visit} listMedicines={listMedicines} deleteTakeMedicines={handleDeleteTakeMedicines}/>
                })
            }
            <AddForm medicineType={medicineType} listMedicines={listMedicines} visit={visit} addListTakeMedicines={handleAddMedicines}/>
        </div>
    </div>;
}

const MedicinesInspectionForm = ({visit}) => {
    const [listMedicinesType, setListMedicinesType] = useState([]);
    const [listMedicines, setListMedicines] = useState([]);
    const [listTakeMedicines, setListTakeMedicines] = useState([]);

    useEffect(() => {
        const fetchMedicinesData = async () => {

            const medicinesTypes = await directoryProvider.getDirectory("medicines_type");
            setListMedicinesType(medicinesTypes);

            const medicines = await directoryProvider.getDirectory("medicines");
            setListMedicines(medicines);

        }

        fetchMedicinesData();

    }, []);

    if (listMedicinesType.length > 0) {
        return <FieldSetVisitForm title={"Лекарства"} id={"base"}>
            {
                listMedicinesType.map(medicinesType => {
                    const listMedicinesByType = listMedicines.filter(item => item.medicines_type === medicinesType.value);
                    return <MedicinesListForm title={medicinesType.discription} medicineType={medicinesType.value} visit={visit} listMedicines={listMedicinesByType}/>
                })
            }
        </FieldSetVisitForm>;
    }

    return <div></div>;

}

export default MedicinesInspectionForm;