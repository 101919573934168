import TitlePartVisit from "./Part/TitlePartVisit";
import FieldSetVisitForm from "./Part/FieldSetVisitForm";
import BaseIdentificationForm from "./Identification/BaseIdentificationForm";
import SocialIdentificationForm from "./Identification/SocialIdentificationForm";
import DisabilityIdentificationForm from "./Identification/DisabilityIdentificationForm";
import InvalidnostIdentificationForm from "./Identification/InvalidnostIdentificationForm";
import PrivichkiIdentificationForm from "./Identification/PrivichkiIdentificationForm";
import MedicinesInspectionForm from "./Inspection/MedicinesInspectionForm";

const InspectionPatientVisit = ({visit}) => {

    return <div className={" py-10 px-10  border-b"}>
        <TitlePartVisit title={"СВЕДЕНИЯ О ЛЕЧЕНИИ  ЗАБОЛЕВАНИЯ  "}/>
        <MedicinesInspectionForm visit={visit}/>

    </div>
}

export default InspectionPatientVisit;