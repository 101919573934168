import apiService from "./ApiService";

class FieldChangeProvider {

    async setFieldIIN(iinValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        } else if (quant == null) {
            quant = 1;
        }

        let params = {
            peremtype: "iin",
            iin: iinValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
    }

    async setFieldVisit(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "visit",
            visit: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldAnalys(idValue, field, value, quant = 1) {

        let params = {
            peremtype: "analys",
            analys: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
    }

    async setFieldRadiography(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "radiography",
            radiography: idValue,
            peremname: field,
            quant: quant
        };

        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldFractures(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        }

        let params = {
            peremtype: "fractures",
            fractures: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

    async setFieldTakeMedicine(idValue, field, value, quant = 1) {

        if (quant === true) {
            quant = 1;
        } else if (quant === false) {
            quant = 0;
        } else if (quant === null) {
            quant = 1;
        }

        let params = {
            peremtype: "takemedicine",
            takemedicine: idValue,
            peremname: field,
            quant: quant
        };
        params[field] = value;

        const fieldSetFetch = await apiService.post('/edit', params);
        return fieldSetFetch;
    }

}


const fieldChangeProvider = new FieldChangeProvider();
export default fieldChangeProvider;